import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "date-and-time-pickers-allow-users-to-select-a-single-or-a-range-of-dates-and-times"
    }}>{`Date and time pickers allow users to select a single or a range of dates and times.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Content</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Interaction</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Time picker</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Range date picker`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To select a range of dates, accompanied by a calendar widget.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Single date picker`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When a user needs to select one date, accompanied by a calendar widget.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simple date picker`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When the date is known by the user and they don’t need a calendar to anticipate the dates.`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "183.42391304347825%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "range, single, and simple date pickers",
        "title": "range, single, and simple date pickers",
        "src": "/static/a113bde3a8c41d1900a57317de96cafd/fb070/date-picker-usage-1.png",
        "srcSet": ["/static/a113bde3a8c41d1900a57317de96cafd/d6747/date-picker-usage-1.png 288w", "/static/a113bde3a8c41d1900a57317de96cafd/09548/date-picker-usage-1.png 576w", "/static/a113bde3a8c41d1900a57317de96cafd/fb070/date-picker-usage-1.png 1152w", "/static/a113bde3a8c41d1900a57317de96cafd/c3e47/date-picker-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <h4 {...{
      "id": "labels"
    }}>{`Labels`}</h4>
    <p>{`Both date and time pickers are accompanied by labels, and follow the same accessibility guidelines for all `}<a parentName="p" {...{
        "href": "/components/form"
      }}>{`forms`}</a>{`.`}</p>
    <h4 {...{
      "id": "format"
    }}>{`Format`}</h4>
    <p>{`For date pickers, use placeholder text so users input the date in the correct format. It can be formatted in a variety of ways. See the date picker code `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-components/blob/master/packages/components/src/components/date-picker/README.md"
      }}>{`documentation`}</a>{` for more info.`}</p>
    <h2 {...{
      "id": "interaction"
    }}>{`Interaction`}</h2>
    <h4 {...{
      "id": "calendar-widget"
    }}>{`Calendar widget`}</h4>
    <p>{`It is recommended to use the date picker with a calendar widget when the user is browsing between a range of dates. Aid the user in making the proper choices by providing a visual reference of dates to choose from. The calendar widget appears once the user has interacted with the date input field (typically on `}<inlineCode parentName="p">{`:focus`}</inlineCode>{`).`}</p>
    <img {...{
      "src": "/date-picker-usage-animation-1-a0c6cd407391b2a80723d32f93dcae8e.gif",
      "alt": "example of date picker"
    }}></img>
    <h4 {...{
      "id": "simple-date-picker"
    }}>{`Simple date picker`}</h4>
    <p>{`The simple date picker provides the user with a text input in which they can input month/day/year. Simple date pickers are typically used when the date is known by the user, such as a birthday or credit card expiration.`}</p>
    <h2 {...{
      "id": "time-picker"
    }}>{`Time picker`}</h2>
    <p>{`Time pickers provide the user with a text input in which they can input hours/minutes. Additionally, they can be accompanied by an “AM/PM” selection and a time zone selection, which is styled as an `}<a parentName="p" {...{
        "href": "/components/select"
      }}>{`inline select`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      